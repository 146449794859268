import React from "react" 
import {Helmet} from "react-helmet";
import "../../../assets/scss/Main.css"
import logo from "../../../assets/img/Main/logo02.png"
import $ from "jquery"


class MainNav extends React.Component {
  componentDidMount() {
    $(window).on('load', function() {
      $('#loading').delay(1000).fadeOut(1500);
    });
  }
    render() {
      return(
        <div>
            <div id="loading">
                <img id="loading-image" src={logo}  alt="Loading..." />
            </div>
        </div>
      )
    }
}

export default MainNav;