import React from 'react'
import { Helmet } from 'react-helmet'
import '../../../assets/scss/Main.css'
import slide01_01 from '../../../assets/img/Main/slidecard01_01.png'
import slide01_02 from '../../../assets/img/Main/slidecard01_02.png'
import slide01_03 from '../../../assets/img/Main/slidecard01_03.png'
import slide02_01 from '../../../assets/img/Main/slidecard02_01.png'
import slide02_02 from '../../../assets/img/Main/slidecard02_02.png'
import slide02_03 from '../../../assets/img/Main/slidecard02_03.png'
import $ from 'jquery'
import logo from '../../../assets/img/Main/logo02.png'

class Slider extends React.Component {
  componentDidMount() {
    $(window).on('load', function() {
      $('#loading')
        .delay(1000)
        .fadeOut(1500)
    })
  }
  render() {
    const style = {
      ImgStyle01_01: {
        backgroundColor: '#209699',
        backgroundImage: `url(${slide01_01})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        href: 'https://type.jp/job-1/1218870_detail/',
      },
      ImgStyle01_02: {
        backgroundColor: '#64afb5',
        backgroundImage: `url(${slide01_02})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      ImgStyle01_03: {
        backgroundColor: '#209699',
        backgroundImage: `url(${slide01_03})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      ImgStyle02_01: {
        backgroundColor: '#094aaf',
        backgroundImage: `url(${slide02_01})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      ImgStyle02_02: {
        backgroundColor: '#3870bf',
        backgroundImage: `url(${slide02_02})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      ImgStyle02_03: {
        backgroundColor: '#094aaf',
        backgroundImage: `url(${slide02_03})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    }
    return (
      <div>
        <Helmet>
          <script type="text/javascript" src="/jquery-3.3.1.js"></script>
          <script
            type="text/javascript"
            src="/jquery-migrate-1.4.1.js"
          ></script>
          <script
            type="text/javascript"
            src="https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"
          ></script>
          <link rel="stylesheet" type="text/css" href="/Main.css" />
          <link rel="stylesheet" href="/jquery.mCustomScrollbar.css" />
          <script type="text/javascript" src="/_main-thum-slide.js"></script>
          <script type="text/javascript" src="/_main-visu-slider.js"></script>
        </Helmet>
        <div id="loading">
        <img id="loading-image" style = {{ width : '30%' , height : 'auto'}} src={logo} alt="Loading..." />
        </div>
        <div className="main-visu-sec">
          <div id="prev" alt="Prev" title="Prev">
            <div className="arrow-left"></div>
          </div>
          <div id="next" alt="Next" title="Next">
            <div className="arrow-right"></div>
          </div>
          <div id="slider">
            <div className="slide" id="one">
              <div className="con-content">
                <div className="content three-content">
                  <h1>
                    Global Company Providing Total, High-Quality Integration
                    Service.
                  </h1>
                  <h3 className="tit-h3">
                    自信と誇りを持って、<br></br>価値創出を追求するSI企業
                  </h3>
                  <div className="visu-btn">
                    <a
                      className="visu-btn__box visu-btn__box--two"
                      style={style.ImgStyle02_01}
                      href="recruit01"
                      
                    >
                      <div></div>
                      <h3>インフラエンジニア</h3>
                      <p>（ネットワーク・サーバ・クラウド）</p>
                    </a>

                    <a
                      className="visu-btn__box visu-btn__box--three"
                      style={style.ImgStyle02_02}
                      href="recruit03"
                      
                    >
                      <div></div>
                      <h3>開発エンジニア</h3>
                      <p>（IoT・AI・Python・Java・Ruby）</p>
                    </a>
                    <a
                      className="visu-btn__box visu-btn__box--four"
                      style={style.ImgStyle02_03}
                      href="recruit02"
                      
                    >
                      <div></div>
                      <h3>無線通信エンジニア</h3>
                      <p>（4G・5G・モバイル）</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="left-over"></div>
                <div className="one-bg"></div>
              </div>
            </div>
            <div className="slide" id="two">
              <div className="con-content">
                <div className="content three-content">
                  <h1>
                    Global Company Providing Total, High-Quality Integration
                    Service.
                  </h1>
                  <h3 className="tit-h3">
                  自信と誇りを持って、<br></br>価値創出を追求するSI企業
                  </h3>
                  <div className="visu-btn">
                    <a
                      className="visu-btn__box visu-btn__box--two"
                      style={style.ImgStyle01_01}
                      href="/recruit01"
                      
                    >
                      <div></div>
                      <h3>インフラエンジニア</h3>
                      <p>（ネットワーク・サーバ・クラウド）</p>
                    </a>

                    <a
                      className="visu-btn__box visu-btn__box--three"
                      style={style.ImgStyle01_02}
                      href="recruit03"
                      
                    >
                      <div></div>
                      <h3>開発エンジニア</h3>
                      <p>（IoT・AI・Python・Java・Ruby）</p>
                    </a>
                    <a
                      className="visu-btn__box visu-btn__box--four"
                      style={style.ImgStyle01_03}
                      href="recruit02"
                      
                    >
                      <div></div>
                      <h3>無線通信エンジニア</h3>
                      <p>（4G・5G・モバイル）</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="left-over"></div>
                <div className="two-bg"></div>
              </div>
            </div>
            <div className="slide" id="three">
              <div className="con-content">
                <div className="content three-content">
                  <h1>
                    Global Company Providing Total, High-Quality Integration
                    Service.
                  </h1>
                  <h3 className="tit-h3">
                  自信と誇りを持って、<br></br>価値創出を追求するSI企業
                  </h3>
                  <div className="visu-btn">
                    <a
                      className="visu-btn__box visu-btn__box--two"
                      style={style.ImgStyle02_01}
                      href="recruit01"
                      
                    >
                      <div></div>
                      <h3>インフラエンジニア</h3>
                      <p>（ネットワーク・サーバ・クラウド）</p>
                    </a>

                    <a
                      className="visu-btn__box visu-btn__box--three"
                      style={style.ImgStyle02_02}
                      href="recruit03"
                      
                    >
                      <div></div>
                      <h3>開発エンジニア</h3>
                      <p>（IoT・AI・Python・Java・Ruby）</p>
                    </a>
                    <a
                      className="visu-btn__box visu-btn__box--four"
                      style={style.ImgStyle02_03}
                      href="recruit02"
                      
                    >
                      <div></div>
                      <h3>無線通信エンジニア</h3>
                      <p>（4G・5G・モバイル）</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="left-over"></div>
                <div className="three-bg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Slider
