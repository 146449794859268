// import React, { useState, useEffect } from 'react'
import React from 'react'
import MainNav from '../../components/Main/MainNav/MainNav'
import MainNav_m from '../../components/Main/Header/MainNav_m'
import Slider from '../../components/Main/Header/Slider'
import Card from '../../components/Main/Card/Card'
import Footer from '../../components/Main/Footer/Footer'
import withStyles from '@material-ui/core/styles/withStyles'
import componentsStyle from '../../assets/jss/material-kit-react/views/components'
import Loading from '../../components/Main/Loading/loading.jsx'

function Main(props) {
  // const { classes, ...rest } = props
  // const [isLoading, setLoading] = useState(true)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 1000)
  // }, [])
  // if (isLoading) {
  //   return <Loading />
  // }
  return (
    <div style={{ backgroundColor: 'white' }}>
        {/* <LoadingClose /> */}
        {/* <Loading /> */}
        <MainNav />
        <MainNav_m />
        <Slider />
        <Card />
        <Footer />
    </div>
  )
}

export default withStyles(componentsStyle)(Main)
