import React, {useState} from 'react'
import Carousel from 'react-slick'
import withStyles from '@material-ui/core/styles/withStyles'
import '../../../assets/scss/Main.css'
import carouselStyle from 'assets/jss/material-kit-react/views/componentsSections/carouselStyle.jsx'
import BackImage from '../../../assets/img/Main/cardbg02.png'
import Text from '../../../assets/img/Main/cardText.png'

function MainCarousel(props) {
  const {classes} = props
  const [images, setImages] = useState([
    'brand01.png',
    'brand02.png',
    'brand03.png',
    'brand04.png',
    'brand06.png',
    'brand07.png',
    'brand08.png',
    'brand09.png',
    'brand10.png',
  ]);
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
  }
  const style = {
    divstyle: {
      backgroundImage: `url(${BackImage})`,
      backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // height: '90%',
      // paddingLeft: '23%',
      // paddingRight: '0%',
      // height:'415px'
      // marginTop: '-16%'
    },
  }
  return (
    <div style={style.divstyle}>
      <div className="main-card-con" >
      <div className="carouseldiv">
      <img src={Text} className="carouselText"></img>
        <Carousel {...settings}>
          {images.map((item) => {
            return (
              <div style={{opacity: '0.9'}}>
                <img
                  src={require(`../../../assets/img/Brand/${item}`)}
                  className="slick-image"
                  
                />
              </div>
            )
          })}
        </Carousel>
      </div>

        </div>   
     
     
    </div>
    // <div>
    //   <div style={style.divstyle}>
    //     <GridContainer>
    //       <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
    //         <Card carousel>
    //
    //         </Card>
    //       </GridItem>
    //     </GridContainer>
    //   </div>
    // </div>
  )
}
export default withStyles(carouselStyle)(MainCarousel)