import React from 'react'
// import { Helmet } from 'react-helmet'
import '../../../assets/scss/Main.css'
import '../../../assets/scss/jquery.mCustomScrollbar.css'
import Img5_1 from '../../../assets/img/Main/5-1.png'
import Img5_2 from '../../../assets/img/Main/5-2.png'
import Img5_3 from '../../../assets/img/Main/5-3.png'
import Img5_4 from '../../../assets/img/Main/5-4.png'
import Img5_5 from '../../../assets/img/Main/5-5.png'
import recruit from '../../../assets/img/Main/recruit.png'
import recruitbtn from '../../../assets/img/Main/recruitbtn.png'
import mid from '../../../assets/img/Main/mid.png'
// import cardImg01 from '../../../assets/img/Main/card02.png'
// import cardImg02 from '../../../assets/img/Main/card02-01.png'
import cardImg02 from '../../../assets/img/Main/cardImg02_02.png'
import cardImg03 from '../../../assets/img/Main/card03_04.png'
import map01 from '../../../assets/img/Main/map01.png'
import map02 from '../../../assets/img/Main/map02.png'
import MainCarousel from '../Header/MainCarousel'
import aboutimg01 from '../../../assets/img/main/cardbg.png'
import bar from '../../../assets/img/Main/bar.png'
import mailBtn from '../../../assets/img/Main/mailBtn.png'
import telicon from '../../../assets/img/Main/telicon.png'
import mapicon from '../../../assets/img/Main/mapicon.png'

// import Slider from '../../../components/Main/Header/Slider'
class Card extends React.Component {
  render() {
    const style = {
      boxTop01: {
        backgroundColor: 'rgb(231, 235, 243)',
        paddingTop: '3%',
      },
      boxTop02: {
        backgroundColor: 'rgb(231, 235, 243)',
        paddingTop: '8%',
      },
      ImgUrl01: {
        backgroundImage: `url(${Img5_1})`,
      },
      ImgUrl02: {
        backgroundImage: `url(${Img5_2})`,
      },
      ImgUrl03: {
        backgroundImage: `url(${Img5_3})`,
      },
      ImgUrl04: {
        backgroundImage: `url(${Img5_4})`,
      },
      ImgUrl05: {
        backgroundImage: `url(${Img5_5})`,
      },
      card02Url: {
        backgroundImage: `url(${cardImg02})`,
      },
      cardImg01: {
        paddingLeft: '22%',
        width: '100%',
        height: '80%',
      },
      cardImg01div: {
        backgroundColor: '#f7f7f7',
        width: '100%',
        height: '80%',
      },
      cardImg02: {
        paddingTop: '3%',
        paddingBottom: '3%',
        align: 'center',
        width: '99%',
        height: '100%',
        position: 'relative',
      },
      recruitImg: {
        align: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
      },
      recruitCircle: {
        align: 'center',
        width: '83%',
        marginTop: '-10%',
        marginBottom:'5%',
        left: '9%',
        // position: 'relative',
      },
      recruitBtn: {
        align: 'center',
        width: '25%',
        left: '36%',
        marginTop: '-10%',
        marginBottom: '5%',
        position: 'relative',
      },
      text: {
        position: 'absolute',
        top: '40px',
        left: '100px',
      },
      mapdiv: {
        paddingTop: '1%',
        paddingBottom: '1%',
      },
      map01: {
        paddingLeft: '20%',
        width: '80%',
        height: '80%',
      },
      gooleMap01: {
        width: '35%',
        height: '100%',
        border: '0',
        allowfullscreen: '',
        loading: 'lazy',
      },
      gooleMap02: {
        marginLeft: '6%',
        width: '35%',
        height: '100%',
        border: '0',
        allowfullscreen: '',
        loading: 'lazy',
      },
      aboutdiv: {
        paddingTop: '3%',
        width: '80%',
        height: '100%',
        marginLeft: '8%',
        // backgroundImage: `url(${aboutimg01})`,
        // backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        paddingBottom: '1%',
      },
      spanStyle: {
        Color: '#ffffff',
        fontSize: '20px',
      },
      calousel: {},
    }
    return (
      <div>
        <div className="main-card">
          <div className="recruitTitle">
            <span> プロジェクト SE・PGエンジニア募集</span>
            <br></br>
            <span className="recruitMsg">We Need Your Support</span>
            <br></br>
            <img src={bar} className="recruitBar" />
          </div>
          <div className="main-card-con" style={{ paddingTop: '-2%' }}>
            <div className="main-card-con__box">
              <div className="main-card-con__box--top" style={style.boxTop01}>
                <p>
                  <span>
                    <br></br>
                    開　発
                  </span>
                </p>
              </div>
              <div
                className="main-card-con__box--img page-trans2"
                style={style.ImgUrl01}
              >
                <a href="/work01"></a>
              </div>
              <div className="main-card-con__box--tex">
                <h3>
                  Web系×スマホ系×業務系×組込み系アプリなどの
                  企画・提案～開発までもの作りをリードします。
                  <br />
                  <br />
                  &nbsp;
                </h3>
              </div>
            </div>
            <div className="main-card-con__box">
              <div className="main-card-con__box--top" style={style.boxTop02}>
                <p>
                  <span>
                    ネットワーク<br></br>セキュリティ
                  </span>
                </p>
              </div>
              <div
                className="main-card-con__box--img page-trans2"
                style={style.ImgUrl02}
              >
                <a href="/work02"></a>
              </div>
              <div className="main-card-con__box--tex">
                <h3>
                  ネットワークのコンサルテーション・要件定義・設計・構築・検証・運用フェーズまで特化しております。
                  <br />
                  <br />
                </h3>
              </div>
            </div>
            <div className="main-card-con__box">
              <div className="main-card-con__box--top" style={style.boxTop01}>
                <p>
                  <span>
                    <br></br>
                    モバイル移動体通信
                  </span>
                </p>
              </div>
              <div
                className="main-card-con__box--img page-trans2"
                style={style.ImgUrl03}
              >
                <a href="/work03"></a>
              </div>
              <div className="main-card-con__box--tex">
                <h3>
                  次世代のモバイル移動通信である5Gをはじめ、主流である４Gの基地局設計・構築・検証・電波測定・保守まで最適化と柔軟な通信環境作りを実現します。
                </h3>
              </div>
            </div>
            <div className="main-card-con__box">
              <div className="main-card-con__box--top" style={style.boxTop01}>
                <p>
                  <span>
                    <br></br>
                    システム
                  </span>
                </p>
              </div>
              <div
                className="main-card-con__box--img page-trans2"
                style={style.ImgUrl04}
              >
                <a href="/work04"></a>
              </div>
              <div className="main-card-con__box--tex">
                <h3>
                  システムのコンサルテーション・導入・設計・構築・検証・運用・監視し、安全なインフラを守り、社会づくりに貢献します。
                  <br></br>
                  <br></br>
                </h3>
              </div>
            </div>
            <div className="main-card-con__box">
              <div className="main-card-con__box--top" style={style.boxTop01}>
                <p>
                  <span>
                    <br></br>
                    データベース
                  </span>
                </p>
              </div>
              <div
                className="main-card-con__box--img page-trans2"
                style={style.ImgUrl05}
              >
                <a href="/work05"></a>
              </div>
              <div className="main-card-con__box--tex">
                <h3>
                  データベースの分析・チューニング・設計・構築・検証・運用まで一貫して構築サービス提供します。
                  <br />
                  <br />
                </h3>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: '4%', paddingBottom: '4%' }}>
            <div className="main-card-con">
              <div className="cardimg02">
                <div className="cardimg02Msgdiv">
                  <br></br>
                  <div className="cardimg02Msg01">｢社員がさみしくならず</div>
                  <div className="cardimg02Msg01">個を尊重した職場作り」を</div>
                  <div className="cardimg02Msg01">
                    第一優先事項に掲げています。{' '}
                  </div>
                  <div className="cardimg02Msg02">正社員だから安定の環境</div>
                  <div className="cardimg02sm">
                    落ち着いて仕事に取り組めます。
                  </div>
                  <div className="cardimg02Msg03">
                    エンジニアとしてスキルアップ
                  </div>
                  <div className="cardimg02sm">
                    エンジニアの成長環境を提供します。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="recruitTitle">
            <span>採用までの４ステップ！</span>
            <br></br>
            <span className="recruitMsg">４steps to Get a Job in GHI</span>
            <br></br>
            <img src={bar} className="recruitBar" />
            <div style={{ position: 'relative' }}>
              <img src={mid} className="midImg"></img>
            </div>
          </div>
          <div className="cardImg01div">
            <div className="main-card-con">
              <div>
                <div>
                  <img
                    src={cardImg02}
                    style={style.recruitImg}
                    loading="eager"
                  ></img>
                </div>
                <img
                  src={recruit}
                  style={style.recruitCircle}
                  loading="eager"
                ></img>
                <a href="/contact">
                  <img
                    src={recruitbtn}
                    style={style.recruitBtn}
                    loading="eager"
                  ></img>
                </a>
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: '#4064ff' }}>
            <div>
              {/*<img src={aboutimg01} style={style.aboutdiv} loading="eager"></img>*/}
              <MainCarousel />
            </div>
          </div>

          <div style={style.cardImg01div}>
            <div className="main-card-con">
              <img src={cardImg03} style={style.cardImg02}></img>
            </div>
          </div>
          <div style={{ paddingTop: '3%', paddingBottom: '3%' }}>
            <div className="recruitTitle02">
              GHインテグレーション株式会社・アクセス
            </div>
            <div className="recruitMsg02">
              <div className="detail" style={{ textAlign: 'center' }}>
                GHIのお問い合わせ・ご相談などございましたら、お気軽にお問い合わせください。
                <a href="/access">
                  <img src={mailBtn} className="mailbtn"></img>
                </a>
                <br></br>お電話によるご相談も承っております。
              </div>
              <div className="detailmo">
                GHIのお問い合わせ・ご相談などございましたら、お気軽にお問い合わせください。
                <a href="/access">
                  <img src={mailBtn} className="mailbtn"></img>
                </a>
                <br></br>お電話によるご相談も承っております。
              </div>

              <span></span>
            </div>
          </div>
          <div className="main-card-con" style={{ paddingLeft: '0%' }}>
            <div style={{ marginBottom: '3%', width: '100%' }}>
              <div className="googlemap01">
                <hr
                  style={{
                    border: 'solid 1px rgb(219, 218, 218)',
                    marginBottom: '4%',
                  }}
                />
                <span className="googlemapTitle">・本社</span>
                <div className="googlemapDetail01">
                  <img src={mapicon} className="mapicon"></img> 〒102-0094
                  東京都千代田区紀尾井町4-1 ニューオータニガーデンコート 22F
                </div>
                <div className="googlemapDetail03">
                  <img src={telicon} className="mapicon"></img> 03-6910-0947
                  Fax:03-6910-0948
                </div>
                <div className="mapdiv">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.498513382287!2d139.71890901500225!3d35.68934823713471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d096948f559%3A0xa7dda6a86526f788!2zR0jjgqTjg7Pjg4bjgrDjg6zjg7zjgrfjg6fjg7PmoKrlvI_kvJrnpL4!5e0!3m2!1sja!2sjp!4v1626827225424!5m2!1sja!2sjp"
                    className="mapSize"
                  ></iframe>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/*
        <div style={style.mapdiv}>
          <img src={map01} style={style.map01}></img>
        </div> */}
      </div>
    )
  }
}
export default Card

